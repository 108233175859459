$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";

// Glyphicons font path
$icon-font-path: "../fonts/";

// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

// Colors
$brand-primary: #00339d;
$navbar-inverse-color: #00339d;
$navbar-inverse-link-color: #00339d;
$white: #fff;


$font-family-base: "futura-pt", "helvetica neue", arial, sans-serif;
$font-family-sans-serif: $font-family-base;
$font-size-base: 18px;

$grid-float-breakpoint: 992px;

$font-size-h3: 22px;

$border-radius-base: 2px;
$border-radius-large: 2px;
$border-radius-small: 2px;

// Breakpoint variables
$width_mobile: 480px;
$width_phablet: 560px;
$width_tablet: 768px;
$width_laptop: 992px;
$width_desktop: 1200px;

//Side-Nav
$sideNavWidth: 240px;
$sideNavDirection: (right);

$button_width: 40px;     // width of button area
$button_height: 60px;     // height of button area
$bar_thickness: 6px;     // thickness of button bars
$bar_roundness: 6px;
$button_pad: 0px;      // left/right padding between button area and bars.
$button_bar_space: 8px;     // spacing between button bars
$button_transistion_duration: 0.3s;  // transition duration
$hamburger_normal: $brand-primary;
$hamburger_active: $brand-primary;

/*
 * Mixins
 */
// Media query mixins
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }
