.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    position: fixed;
    z-index: 1000;
    height: 100%;
    top: 0;
    background: $brand-primary;
    transition: all 0.3s ease;
    box-shadow: inset 8px 0 10px -7px rgba(0,0,0,.6);
    padding: 20px 0;

    h3 {
        color: #fff;
        font-size: 24px;
        padding: 20px;
        margin: 0;
        font-weight: 300;
        background: #111;
    }

    a {
        display: block;
        color: #fff;
        font-size: 18px;
        padding: 20px;

        &:hover {
            background: lighten($brand-primary, 20);;
            text-decoration: none;
            color: #000;
        }

        &:active {
            background: $brand-primary;
            color: #000;
        }

    }

    &.sideNav-open {
        #{$sideNavDirection}: 0px;
    }
}

/* classes applied to the body */
.sideNavBody {
    overflow-x: hidden;
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.3s ease;
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;
}
