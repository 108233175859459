.btn-cta {
  @include button-variant(#fff, #ff6600, lighten(#ff6600, 20));
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  margin: 40px 0 20px 0;
}

h2 {
  padding-bottom: 40px;
  margin: 0px;
  font-weight: 200;
}

.img-center {
  margin: 0 auto;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
}
.video-container iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

a:hover {
  text-decoration: none !important;
  color: lighten($brand-primary, 20);
}

.inner-error404 {
    padding-top: 40px;
    padding-bottom: 40px;
}
