// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.gform_wrapper {
  .gform_fields {
    padding: 0;

    .gfield {
      padding: 0;
      margin-bottom: 15px;
      text-align: left;

      .gfield_label {
        line-height: 23px;
        padding-left: 10px;
        margin: 0 0 12.5px;
        font-weight: normal;
        font-size: 18px;
        color: #333;
      }

      input {
        height: 39px;
      }

      textarea {
        height: 120px;
      }

      input,
      textarea {
        display: block;
        width: 100%;
        padding: 6px 12px;
        font-size: 18px;
        line-height: 1.428571429;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 2px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      }

      &.gfield_html {
        font-size: 16px;
      }

      .gfield_checkbox {
        padding: 0;

        label {
          padding: 0;
          margin: 0;
          font-size: 20px;
          font-weight: normal;
          vertical-align: middle;
        }

        input {
          display: inline;
          width: auto;
          height: auto;
          vertical-align: middle;
          padding: 0;
          margin: 0;
        }
      }

      &.g-recaptcha .gfield_label {
        @extend .sr-only;
      }
    }
  }

  .gform_footer {
    margin-bottom: 20px;

    .gform_button {
      display: block;
      margin: 0;
      color: #fff;
      background-color: #f60;
      border-color: #ffa366;
      width: 200px;

      &:focus, &:hover, &:active  {
        background-color: darken(#ff6600, 20);
        border-color: #ffa366;
      }
    }
  }
}
