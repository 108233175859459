// Home Page
.lead {
  font-size: 20px;
}

.main {
  @include laptop {
    padding: 0;
  }
  overflow: hidden;
}

.hero {
  position: relative;
  color: #1f1f1f;
  height: 850px;
  background-color: #bfbfbf;
  @include mobile {
    background: url('/wp-content/uploads/couple-on-beach-3-1.jpg') no-repeat top center;
    background-size: cover;
  }
  &:before {
   content: '';
   position: absolute;
   background-image: url("/wp-content/uploads/couple-on-beach-3-1.jpg");
   background-position: top center;
   background-repeat: no-repeat;
   background-size: cover;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 1;
   filter: blur(5px);
   @include phablet {
     display: none;
   }
  }
}
// Interior Hero
.interior-hero {

  .hero-content {
    background-color: rgba(255, 255, 255, 0.7);
    margin:75px 50px;
    padding-bottom: 25px;
    @include laptop {
      margin: 100px 50px;
    }
    .hero-text {
      padding-top:25px;
      margin: 10px;
      text-align: center;
      font-size: 30px;
      line-height: 1;
      @include tablet {
        text-align: left;
        font-size: 35px;
        margin: 25px;
      }
      @include laptop {
        font-size: 40px;
        line-height: 54px;
        }
    }
    .btn {
      display: block;
      margin: auto;
      text-align: center;
      width: 215px;
      font-size: 18px;
      @include tablet {
        font-size: 23px;
        margin: 0 25px 25px;
        width: 300px;
      }
    }
  }

  &.hero-blog {
    background: url('/wp-content/uploads/couple-on-beach-4.jpg') no-repeat top center;
    .hero-text {
      padding-bottom: 0;
    }
    .btn {
      position: relative;
      @include mobile {
      width: 260px;
     }


    }
    }
  }


// end interior hero

.hero-text {
  position: relative;
  z-index: 2;
  padding-top: 100px;
  font-size: 40px;
  line-height: 45px;
  max-width: 375px;
  text-shadow: 0 0 10px #fff;
  @include phablet {
      padding-top: 200px;
  }
  @include laptop {
    max-width: 500px;
    font-size: 54px;
    line-height: 54px;
  }
  @include desktop {
    max-width: 100%;
  }
}

.hero-subtext {
  position: relative;
  z-index: 2;
  padding-top: 10px;
  max-width: 375px;
  font-weight: 500;
  text-shadow: 0 0 10px #fff;
  @include laptop {
    max-width: 100%;
    padding-right: 120px;
  }
}



.strapline {
  color: #fff;
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 0;
  padding-top: 25px;
  padding-bottom: 20px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, .45);
  font-size: 20px;
  @include laptop {
    font-size: 22px;
  }
}

.why-us {
  background: linear-gradient(
                  rgba(255, 255, 255, 0.90),
                  rgba(255, 255, 255, 0.90)
  ),
  url('/wp-content/uploads/sigma-background.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  .lead {
    max-width: 275px;
    margin: auto;
    @include phablet {
      max-width: 500px;
    }
    @include tablet {
        max-width: 700px;
    }
    @include laptop {
        max-width: 800px;
      }
  }
  img {
    margin-top: 50px;
  }
}

.full-panel {
  padding-top: 60px;
  padding-bottom: 60px;
}

.social-proof {
  padding-top: 45px;
  padding-bottom: 40px;
  background: linear-gradient(
                  rgba(0, 51, 157, 0.95),
                  rgba(0, 51, 157, 0.95)
  ),
  url('/wp-content/uploads/couple-on-beach.jpg') no-repeat top right;
  background-size: cover;
  color: #fff;
  .customer-name {
    text-align: center;
  }
  @include tablet {
    padding: 30px;
  }
}


//  Interior Pages
.page-content {
  h1 {
    text-align: center;
    font-size: 35px;
    @include tablet {
      text-align: left;
      font-size: 46px;
    };
  }
  h4 {
    font-size: 22px;
  }
  ul {
    padding: 10px;
    @include laptop {
      padding: 0;
    }
  }
  p {
    line-height: 23px;
    @include tablet {
          padding-left: 10px;
    }
  }
  li {
    list-style: none;
    padding-bottom: 8px;

}


// Contact Form & Page

  .wpcf7 {
    height: auto;
    @include tablet {
    margin-bottom: 75px;
  }
  .wpcf7-response-output {
      margin-top: 30px;
  }
  .btn.wpcf7-submit {
    margin: auto;
    display: block;
    @include tablet {
      clear: both;
      float: left;
    };
  }
  p.disclaimer {
    font-size: 16px;
    text-align: center;
    padding: 10px 0 0;
    @include tablet {
      clear: both;
      text-align: left;
    };
  }
  .checkbox {
  text-align: center;
  @include tablet {
    clear: both;
    text-align: left;
  };
  }
  //   .alert {
  //     display: none !important;
  // }
}
.contact-phone {
  padding-bottom: 50px;
}
.wdm-nocapt-recapt {
  display: block;
  margin: auto;
  @include  tablet {
  float: left;
    }
  }
}
.care-contact {
  .wpcf7 {
    margin-bottom: 110px;
    @include laptop {
        margin-bottom: 200px;
    }
  }

}

.contact-address {
  text-align: center;
  font-size: 20px;

  @include laptop {
  text-align: left;
  // margin: 25px 0 0;
  }
}

.footer-map {
  // margin-top: 100px;
  @include laptop {
    // margin-top: 75px;
  }
}

//Interior Hero
.interior-hero {
  position: relative;
  height: 400px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  }

  // Blog
.cline-blog {
  padding-bottom: 50px;
  h4 {
    padding-bottom: 20px;
  }
  h2 {
    font-size: 35px;
    padding-bottom: 10px;
  }
  article {
    padding-bottom: 20px;
    &:first-of-type{
      padding-top: 50px;
    }
  }
  a {
    text-decoration: none;
  }
  a:hover {
    color: lighten($brand-primary, 20);
  }
  .btn-cta:hover {
    color: $white;
  }

  .entry-content{
    font-size: 18px;
    margin-bottom: 50px;
    text-indent: 25px;
  }
}
  // Recent Posts
.widget_recent_entries {
  padding-top: 50px;
  h3 {
    font-size: 25px;
  }
  ul {
      list-style: none;
      padding: 0;
  }
  li {
    font-size: 20px;
    padding-bottom: 8px;
  }
}

// Our Doctors - Video
.video {
  text-align: center;
  text-decoration: none;
  a {
    text-decoration: none;
  }
}
