.site-notice {
  padding: 10px 0;
  background-color: $brand-primary;

  p {
    margin: 0;
    text-align: center;
    font-size: 18px;
    color: $white;

    @include tablet {
      font-size: 18px;
    }
  }

  .notice-buttons {
    display: block;
    margin: 10px 0 5px;

    @include desktop {
      display: inline-block;
      margin: 0 0 0 10px;
    }

    a.btn {
      display: inline-block;
      font-size: 14px;
      line-height: 14px;
      padding: 10px 13px;
      margin: 0 3px;
      min-width: auto;

      @include tablet {
        font-size: 15px;
        line-height: 15px;
      }
    }
  }
}
.logo {
  max-width: 210px;
  margin-top: -30px;

  @include phablet {
    max-width: 300px;
    margin-top: -35px;
  }
  @include laptop {
    padding-top: 3px;
    max-width: 232px;
  }
  @include desktop {
    max-width: 300px;
    padding-top: 0;
  }
}

.navbar.transparent.navbar-inverse {
  background: $brand-primary;
  // border-color: #ccc;
  &:focus {
    background: $brand-primary;
  }

}

.navbar-inverse {
  background: transparent;
  color: $brand-primary;
}

.navbar-inverse .navbar-nav > li > a:focus {
  color: $brand-primary;
}

#menu-primary {
  margin-top: 22px;
}

.navbar-toggle {
  margin-top: 25px;
  color: $brand-primary;

}

.navbar-inverse .navbar-nav li > a {
  color: $brand-primary;
  padding: 10px;
  &:hover {
  color: lighten($brand-primary, 20);
  background-color: transparent;
  }
  // &:visited {
  //   color:$brand-primary;
  //   background-color: transparent;
  // }


  @include laptop {
    padding: 12px;
  }
}
.navbar-inverse .navbar-nav li.active {
            > a, > a:hover, > a:focus {
                color: lighten($brand-primary, 20);
                background-color: #fff; }}
