.btn-cta {
  position: relative;
  z-index: 2;
  margin-left:10px;
  @include laptop {
  margin: 0;
  }
}

.page-content {
  .btn {
    clear: both;
    display: block;
    margin: 20px auto;
    max-width: 325px;
  }
}

.btn-primary {
  color: #fff;
  background-color: #f60;
  border-color: #ffa366;
  width: 200px;
  @include tablet {
      float: right;
  }
}

.slick-next, .slick-prev {
  top: 100%;
  margin: 0 100px;
  @include laptop {
    top: 50%;
    margin: 0 20px;
  }
}
