footer {
  background-color: $brand-primary;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  .nav {
    li:last-child {
      display: none;
    }
    a {
      color: #fff;
      padding: 0;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.cline-doctors {
  padding-top: 60px;
  background: linear-gradient(
                  rgba(255, 255, 255, 0.90),
                  rgba(255, 255, 255, 0.90)
  ),
  url('/wp-content/uploads/dr-cline-with-patient-background.jpg') no-repeat top center;
  background-size: cover;
  background-attachment: fixed;

}

.learn-more {
  text-align: center;
  padding-top: 40px;
  a {
    padding-bottom: 3px;
    border-bottom: 1px dashed;
    &:hover {
      text-decoration: none;
      border-bottom: 1px solid;
    }
  }
}

.footer-logo {
  width: 350px;
  padding: 0 10px 10px;
}

.schedule-appointment {
  padding-top: 20px;
  background-color: #bfbfbf;
  margin-top: 50px;

  .lead {
    text-align: center;
    @include tablet {
      text-align: left;
    }
  }
  .btn-cta {
    margin:0 0 30px 10px;
    @include tablet {
    margin: 30px 0 0;
    }
    @include laptop {
      margin: 0 auto;
    }
  }
}


.business-name {
  display: none;
}

.phone {
  font-size: 20px;
}
span.hours {
  font-size: 16px;
  margin-bottom: 5px;
}

ul.associations {
  list-style-type: none;
  padding: 0;

  li {
    display: inline-block;
    margin: 0;
    padding-right: 20px;
  }
  li:last-of-type {
    padding: 0;
  }
}

.content-mid {
  padding-bottom: 20px;
  @include tablet {
    text-align: left;
  }
}

.assoc {
  max-height: 72px;
  @include tablet {
  max-height: 75px;
  }
}

.footer-nav {

  @include tablet {
    text-align: right;
    padding-right:25px;
  }
  @include laptop {
    text-align: left;
  }
}
.disclaimer {
  p {
    font-size: 14px;
    margin: 0;
    padding: 25px 0 0;
    @include tablet {
      padding: 25px 100px 0;
    }
  }
}

.copyright {
  background-color: #fff;
  font-size: 13px;
  display: block;
  color: #000;
  padding: 8px;
  bottom: 0;
  text-align: center;
  .links-copy {
    a {
      color: #000;
    }
    @include tablet {
      float: left;
      text-align: left;
    }
  }
  .client-copy {
    @include tablet {
      float: right;
      text-align: right;
    }
  }

}
